<template>
  <v-dialog v-model="dialog" max-width="850px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color=""
        title="Visualizar pendências"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          mdi-arrow-top-right-bold-box-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <h5 class="text_error">Parecer e pendências</h5>
          </v-col>
          <v-col align="right">
            <v-btn color="black" right icon dark @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text align="center" justify="center">
        <v-container>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            v-if=loading
          ></v-progress-circular>
          <v-row v-else>
            <v-col>
              <RequestPendencies></RequestPendencies>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Fechar
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import RequestPendencies from '@/components/Request/Pendencies'

export default {
  name: 'RequestPendenciesDialog',
  components: { RequestPendencies },
  props: {
    pendencieId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    loading: false
  }),
  watch: {
    dialog (val) {
      if (val && this.pendencieId) {
        this.loading = true
        let payload = {
          id: this.pendencieId,
          revision: null
        }
        this.getRequest(payload).then(() => {
          this.loading = false
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'getRequest'
    ]),
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
