<template>
  <v-timeline align-top dense>
    <v-timeline-item v-for="(pendencie, i) in reverseRequestPendencies" :key=i :color="timelineDotColor(pendencie)" small >
      <v-row class="pt-1">
        <v-col cols="3">
          <strong>{{pendencie.data_alteracao}}</strong>
          <div class="text-caption div_person">
            <v-icon small>
              mdi-account-edit
            </v-icon>
            {{pendencie && pendencie.user_name ? pendencie.user_name : ''}}
          </div>
        </v-col>
        <v-col>
          <strong>{{pendencie.titulo_parecer}}</strong> <RequestNewPendencie v-if="isGSuser && inAnalysis && !pendencie.revisao_documento_id" :pendencieId=i :edit=true @reloadList=reloadList></RequestNewPendencie>
          <div class="text-caption">{{pendencie.parecer}}</div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestNewPendencie from "./NewPendencie";

export default {
  name: "RequestPendencies",
  components: {
    RequestNewPendencie
  },
  computed: {
    ...mapGetters([
      'requestPendencies',
      'isGSuser',
      'inAnalysis'
    ]),
    reverseRequestPendencies() {
      return this.requestPendencies
    }
  },
  methods: {
    reloadList() {
      this.$emit('reloadPendencies');
    },
    timelineDotColor(pendencie) {
      if(!pendencie.revisao_documento_id) {
        return 'grey'
      }
      return pendencie.is_conforme ? 'success' : 'error'
    }
  }
};
</script>
