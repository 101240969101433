<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if=!edit
        rounded
        color="error"
        small
        class="mt-3 mr-10"
        v-bind="attrs"
        v-on="on"
        @click=initialize
      >
        Novo parecer
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-else text icon v-bind="attrs" v-on="on" @click=initialize title="Editar parecer">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text v-if=loading>
        <v-container>
          <v-row justify="center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <v-container>
          <!--<v-row v-if=edit>
            <v-col>
              <v-switch
                v-model="form.is_conforme"
                label="Pendência finalizada"
              ></v-switch>
            </v-col>
          </v-row>-->
          <v-row>
            <v-col>
              <v-select
                item-text="nome"
                item-value="id"
                no-data-text="Nenhum dado encontrado"
                :items="op_models"
                label="Modelo de parecer"
                @change="selectModel"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.titulo_parecer"
                label="Título"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.parecer"
                label="Descrição"
                auto-grow
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="closeDialog"
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          @click="addPendencie"
          rounded
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

function initialState () {
  return {
    dialog: false,
    form: {
      titulo_parecer: '',
      parecer: '',
      is_conforme: false
    },
    loading: false,
    models: [],
    selectedModel: null
  }
}

export default {
  name: 'RequestNewPendencie',
  props: {
    pendencieId: {
      type: Number,
      required: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters([
      'requestPendencie',
      'feedbackModelList'
    ]),
    op_models () {
      return this.feedbackModelList
    }
  },
  created () {
    this.loading = true
    this.getFeedbackModelList()
      .catch(err => {
        this.$toastr.e(err.response.data.message)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions([
      'savePendency',
      'getFeedbackModelList'
    ]),
    ...mapMutations([
      'setFeedbackModelList'
    ]),
    initialize () {
      Object.assign(this.$data, initialState())
      if (this.pendencieId !== undefined) {
        const pendencie = this.requestPendencie(this.pendencieId)
        this.form.id = pendencie.id
        this.form.titulo_parecer = pendencie.titulo_parecer
        this.form.parecer = pendencie.parecer
        this.form.is_conforme = pendencie.is_conforme
      }
    },
    addPendencie () {
      this.savePendency(this.form).then(() => {
        this.$emit('reloadList')
      })
      this.closeDialog()
    },
    closeDialog () {
      this.dialog = false
    },
    selectModel (val) {
      const model = this.feedbackModelList.filter(f => {
        return f.id == val
      })[0]
      this.form.titulo_parecer = model.titulo
      this.form.parecer = model.descricao
    }
  }
}
</script>
